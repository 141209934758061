import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  inject,
  Injector,
  model,
  viewChild
} from '@angular/core';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FoundItemTableDataSource, FoundItemTableItem } from './found-item-table-datasource';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe, NgClass } from '@angular/common';
import { CategoryPipe } from "../../shared/pipes/category.pipe";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { LossReportFormDialogComponent } from '../../loss-report/loss-report-form-dialog.component';
import { take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../shared/services/loading.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FoundItemFiltersBottomSheetComponent } from './found-item-filters-bottom-sheet.component';
import { FoundItemService } from '../found-item.service';
import { FoundItemFilters } from './found-item-filters.model';
import { MatBadge } from '@angular/material/badge';

@Component({
  selector: 'app-found-item-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  template: `
    <div class="flex flex-col h-full">
      <div class="flex justify-between mb-4" [ngClass]="{ 'pt-3 pr-3': filtersLength() > 0 }">
        <h1 class="m-0">Objets trouvés</h1>
        <button mat-stroked-button [matBadge]="filtersLength()" [matBadgeHidden]="filtersLength() === 0"
                (click)="openFilters()" color="primary" [disabled]="loadingService.isLoading()">
          <mat-icon>filter_alt</mat-icon>
          Filtres
        </button>
      </div>
      <div class="overflow-auto flex flex-col h-full bg-white rounded-md z-10" #scrollable>
        <table mat-table matSort [class.small]="isSmallScreen">
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Catégorie</th>
            <td mat-cell *matCellDef="let row"><span>{{ row.category | category:isSmallScreen ? 'short' : '' }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Couleur</th>
            <td mat-cell *matCellDef="let row">
              {{ row.color }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
            <td mat-cell *matCellDef="let row">
              <span
                class="status rounded-2xl py-1 px-2 leading-4 flex items-center justify-center"
                [ngClass]="{'text-primary-500 border-primary-500 border-2': row.hasLossReport, ' bg-primary-500 text-white': !row.hasLossReport }">
                {{ row.status }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trouvé le</th>
            <td mat-cell *matCellDef="let row" class="relative !pr-7">
              {{ row.date | date:'shortDate' }}
              @if (isSmallScreen) {
                <mat-icon class="h-4 w-4 absolute right-2 top-1/2 transform -translate-y-1/2">
                  chevron_right
                </mat-icon>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <div class="w-full flex justify-center">
                <button mat-stroked-button color="primary whitespace-nowrap"
                        [disabled]="loadingService.isLoading()"
                        (click)="openDialog(row)">
                  <mat-icon fontSet="material-icons-outlined">front_hand</mat-icon>
                  J'ai perdu cet objet
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="border-b-[1px]"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              (click)="isSmallScreen ? openDialog(row): ''"></tr>
        </table>
        <mat-paginator #paginator
                       class="!flex justify-center border-t-[1px] mt-auto"
                       [length]="dataSource.paginator?.length"
                       [pageIndex]="0"
                       [pageSize]="20"
                       [hidePageSize]="isSmallScreen"
                       [pageSizeOptions]="isSmallScreen ? [] : [5, 10, 20]"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  `,
  styles: `
    table.small {
      .mat-mdc-header-cell,
      .mat-mdc-cell {
        @apply px-3;
      }

      .mat-column-category {
        white-space: unset;
        width: 35%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }
    }
  `,
  imports: [MatTableModule, MatPaginatorModule, MatSortModule, MatButtonModule, DatePipe, CategoryPipe, NgClass, MatIcon, MatBadge]
})
export class FoundItemTableComponent implements AfterViewInit {
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: string[] = [];
  isSmallScreen = true;
  loadingService = inject(LoadingService)
  filters = model.required<FoundItemFilters>();
  filtersLength = computed(() => Object.values(this.filters()).filter((v) => !!v).length);

  private breakpointObserver = inject(BreakpointObserver);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private dialog = inject(MatDialog);
  private bottomSheet = inject(MatBottomSheet);
  private paginator = viewChild<MatPaginator>(MatPaginator);
  private sort = viewChild<MatSort>(MatSort);
  private table = viewChild<MatTable<FoundItemTableItem>>(MatTable);
  private snackBar = inject(MatSnackBar);
  private scrollable = viewChild<ElementRef>('scrollable');
  private destroyRef = inject(DestroyRef);

  dataSource = new FoundItemTableDataSource(inject(Injector), this.filters, inject(FoundItemService), this.snackBar);

  constructor() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (state) => {
          console.log(state)
          if (state.matches) {
            this.isSmallScreen = true;
            this.displayedColumns = ['category', 'color', 'date'];
          } else {
            this.isSmallScreen = false;
            this.displayedColumns = ['category', 'color', 'status', 'date', 'actions'];
          }
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort();
    this.dataSource.paginator = this.paginator();
    this.table()!.dataSource = this.dataSource;

    this.paginator()!.page.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.scrollable()?.nativeElement?.scrollTo({ top: 0 });
    });
  }

  openDialog(foundItemTableItem: FoundItemTableItem): void {
    const foundItem = this.dataSource.rawData().find((item) => item.id === foundItemTableItem.id);

    if (this.dataSource.isLoading()) {
      this.snackBar.open('Veuillez patienter, les objets sont en cours de chargement', 'Fermer');
    }
    this.dialog.open(LossReportFormDialogComponent, {
      data: foundItem,
      autoFocus: false,
    }).afterClosed().pipe(
      take(1)
    ).subscribe(() => this.dataSource.refresh());
  }

  openFilters() {
    const ref = this.bottomSheet.open<
      FoundItemFiltersBottomSheetComponent,
      FoundItemFilters,
      FoundItemFilters
    >(FoundItemFiltersBottomSheetComponent, {
      data: this.filters(),
    });
    ref.afterDismissed().pipe(take(1)).subscribe((filters) => {
      if (!filters) return;
      this.filters.set(filters);
    });
  }
}
